import React, {useState} from "react";
import {FormControl, Grid, TextField} from "@mui/material";
import {Login} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {ApiAuthentification} from 'Api/ApiNazar';
import 'Assets/css/Login.css';
import {Bounce, toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../Provider/AuthProvider";
import Constants from "Scripts/Constants";

const Authentication = () => {
    const [loadingButton, setLoadingButton] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {setToken, isConnected} = useAuth();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingButton(true);
        handleLogin(username,password);
    };

    const handleLogin = async (username: string, password: string) => {
        try {
          const response = await ApiAuthentification.fetchUser(username,password);
          setLoadingButton(false);
          console.log(response);
          if(response.status && response.data !== null){
              if(response.data.token !== undefined && response.code === 200){
                  setToken(response.data.token);
              }else{
                  toast.error('Une erreur inconnu est survenu.', {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      transition: Bounce,
                  });
              }
          }else if(response.data !== undefined && response.data.error !== undefined){
              toast.error(`Erreur ${response.code} : ${response.data.error}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
              });
          }else {
              toast.error('Une erreur inconnu est survenu.', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
              });
          }
        } catch (e) {
            toast.error(`Une erreur est survenu : ${e}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }
    if(isConnected()){ return null;}
    return (
        <div className={'mainBlock'} style={{backgroundImage: `url(${Constants.backgroundLogin})`}}>
            <ToastContainer />
            <form onSubmit={handleSubmit} className={'contentBlock'}>
                <div className={'logoBlock'}>
                    <img src={Constants.logo} alt={'Logo Nazar rugs'} style={{objectFit: 'contain'}}
                         width={'100%'} height={'100%'}/>
                </div>
                <Grid container spacing={0} className={'mainBlockLogin'}>
                    <Grid item sm={6} display={{xs: "none", sm: "block"}}>
                        <img src={Constants.imgLogin} alt={'Tapis'} width={'100%'} height={'100%'} style={{objectFit: 'cover'}}/>
                    </Grid>
                    <Grid item xs={12} sm={6} className={'blockInput'}>
                        <FormControl className={'block'}>
                            <div>
                                <h1 style={{textAlign:'center'}}>{Constants.nameApplication} statistiques</h1>
                                <h2 style={{textAlign:'center'}}>Connexion</h2>
                            </div>
                            <div className={'blockGridInput'}>
                                <TextField variant="outlined"
                                           type={'email'}
                                           autoFocus={true}
                                           label={'Login'}
                                           name={'username'}
                                           onChange={(e) => setUsername(e.target.value)}
                                           className={'input'}/>
                                <TextField variant="outlined"
                                           type={'password'}
                                           autoFocus={false}
                                           label={'Mot de passe'}
                                           name={'password'}
                                           onChange={(e) => setPassword(e.target.value)}
                                           className={'input'}/>
                            </div>
                            <div className={'boxBtnLogin'}>
                                <LoadingButton type={'submit'} className={'btnLogin'} loading={loadingButton} loadingPosition="center"
                                               variant="contained" endIcon={<Login/>}>Se connecter</LoadingButton>
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
export default Authentication;
