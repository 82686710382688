import React, {Fragment} from 'react';
import {styled, useTheme, Theme, CSSObject} from '@mui/material/styles';
import List from '@mui/material/List';
import {
    ListItemText,
    ListItemIcon,
    Divider,
    ListItemButton,
    ListItem,
    IconButton,
    Tooltip
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import {useAuth} from "../Provider/AuthProvider";
import {MenuOpen, ArrowRight, Home, Logout, Store} from "@mui/icons-material";
import Constants from "Scripts/Constants";
import GlobalFunctions from "Scripts/GlobalFunctions";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    backgroundColor: 'transparent',
    borderRight: 0,
    [theme.breakpoints.down('sm')]: {
        width: `95%`,
        position: 'fixed',
        backgroundColor: 'white',
    },
    transition: theme.transitions.create(['width','backgroundColor'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create(['width','backgroundColor'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'transparent',
    borderRight: 0,
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.down('sm')]: {
        position: 'relative'
    },
});

const CustomDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        zIndex: 10,

        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Navigation = () => {
    const {user, logout} = useAuth();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpenClose = () => {
        setOpen(!open);
    };

    const Image = styled('img')({
        objectFit: 'contain',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        backgroundColor: 'white',
        boxShadow: theme.shadows[2],
        padding: 6
    });

    const menuItemStyle = ( isActive: boolean, isPending: boolean, isTransitioning: boolean) => {
        let styleBtn: any = {
            color: "black",
            margin: '5px 5px',
            borderRadius: 20
        };

        let styleText: any = {
            color: "black",
        };

        if(isActive){
            styleText.color= GlobalFunctions.addTenToHexColor(Constants.color.mainColor,1);
            styleText.fontWeight = "bold";

            styleBtn.backgroundColor= GlobalFunctions.addTenToHexColor(Constants.color.mainColor,70);
        }

        if(isPending || isTransitioning){
            styleText.color= GlobalFunctions.addTenToHexColor('#b6773d',1);
            styleText.fontWeight = "bold";

            styleBtn.backgroundColor= GlobalFunctions.addTenToHexColor('#b6773d',70);
        }

        return {styleBtn,styleText};
    }

    return (
        <Fragment>
            <CustomDrawer variant="permanent" open={open}>
                <List>
                    <ListItem component="div" sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: open ? 'end' : 'center' }}>
                        <Tooltip title="Close menu">
                            <IconButton
                                onClick={handleDrawerOpenClose}
                                size="large"
                            >
                                {!open ? <ArrowRight/> : <MenuOpen/>}
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                    <Divider />
                    <ListItem component="div" disablePadding sx={{display: 'block'}}>
                        <ListItem sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <Image src={Constants.logo} alt={'Logo'} />
                            </ListItemIcon>
                            <ListItemText sx={{opacity: open ? 1 : 0}}
                                primary={`${user?.firstName} ${user?.lastName}`}
                                primaryTypographyProps={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                    variant: 'body2',
                                }}
                            />
                        </ListItem>
                    </ListItem>
                    <Divider/>
                    <ListItem key={'home'} disablePadding sx={{display: 'block'}}>
                        <NavLink to={'/'} style={{textDecoration: 'none'}}>
                            {({ isActive, isPending, isTransitioning }) => (
                                <ListItemButton style={menuItemStyle(isActive, isPending, isTransitioning).styleBtn} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                        <Home/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Home'} sx={{opacity: open ? 1 : 0}} primaryTypographyProps={menuItemStyle(isActive, isPending, isTransitioning).styleText}/>
                                </ListItemButton>
                            )}
                        </NavLink>
                    </ListItem>
                    <ListItem key={'home'} disablePadding sx={{display: 'block'}}>
                        <NavLink to={'stats/edi'} style={{textDecoration: 'none'}}>
                            {({ isActive, isPending, isTransitioning }) => (
                                <ListItemButton style={menuItemStyle(isActive, isPending, isTransitioning).styleBtn} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                        <Store/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Edi'} sx={{opacity: open ? 1 : 0}} primaryTypographyProps={menuItemStyle(isActive, isPending, isTransitioning).styleText}/>
                                </ListItemButton>
                            )}
                        </NavLink>
                    </ListItem>
                    <ListItem key={'home'} disablePadding sx={{display: 'block'}}>
                        <NavLink to={'stats/product'} style={{textDecoration: 'none'}}>
                            {({ isActive, isPending, isTransitioning }) => (
                                <ListItemButton style={menuItemStyle(isActive, isPending, isTransitioning).styleBtn} sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                        <Home/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Produit'} sx={{opacity: open ? 1 : 0}} primaryTypographyProps={menuItemStyle(isActive, isPending, isTransitioning).styleText}/>
                                </ListItemButton>
                            )}
                        </NavLink>
                    </ListItem>
                    <ListItem key={'home'} disablePadding sx={{display: 'block'}}>
                        <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} onClick={logout}>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <Logout/>
                            </ListItemIcon>
                            <ListItemText primary={'Se déconnecter'} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </CustomDrawer>
        </Fragment>
    );
};

export default Navigation;
