import { ApiResponse, fetchData} from '../ApiService';

const GLOBAL_API = '/Stats/Global.php';
const EDI_API = '/Stats/Edi.php';

const ApiStats = {
    fetchStatsGlobal: (): Promise<ApiResponse> => {
        return fetchData(GLOBAL_API);
    },

    fetchStatsEdi: (): Promise<ApiResponse> => {
        return fetchData(EDI_API);
    },
};

export default ApiStats;
