import React, {useEffect} from 'react';
import {motion, useAnimate, useMotionValue, useTransform} from "framer-motion";
import GlobalFunctions from "Scripts/GlobalFunctions";

interface NumberAnimationInterface {
    valueNumber: number;
    [key: string]: any;
    isEuro?: boolean;
}
const NumberAnimation: React.FC<NumberAnimationInterface> = ({valueNumber, isEuro = true, ...props}) => {
    const [, animate] = useAnimate();
    const valueMotion = useMotionValue(0);
    const transformValue = (value: any) => {
        if(isEuro){
            return GlobalFunctions.toEuro(value);
        }else{
            return value;
        }
    }
    const value = useTransform(valueMotion, transformValue);

    useEffect(() => {
        const animation = animate(valueMotion, valueNumber, {
            duration: 1
        });
        console.log(animation);
        animation.play();
    }, [animate, valueMotion, valueNumber]);
    return (
        <motion.span {...props}>{value}</motion.span>
    )
}

export default NumberAnimation;
