import axios, {AxiosInstance, AxiosResponse} from 'axios';

const API_URL = 'https://ws-nazar.nazarrugs.fr/api/'; // PROD
//const API_URL = 'http://ws.nazarrugs.local/api/'; // TEST

const optionsAxios: any = {
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

export interface ApiResponse {
  status: boolean;
  code: number;
  message: string | null;
  data: any | null;
}

export const fetchData = async <T>(
  endpoint: string,
  params?: any,
): Promise<ApiResponse> => {
  try {
    if(axios.defaults.headers.common.Authorization){
      optionsAxios.headers.Authorization = axios.defaults.headers.common.Authorization
    }else{
      optionsAxios.headers.Authorization = '';
    }
    const apiService: AxiosInstance = axios.create(optionsAxios);
    const response: AxiosResponse<T> = await apiService.get(endpoint, {params});
    return {
      status: true,
      code: response.status,
      message: 'Success',
      data: response.data,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        status: false,
        code: error.response ? error.response.status : 500,
        message: error.message,
        data: null,
      };
    } else {
      return {
        status: false,
        code: 500,
        message: 'Unknown Error',
        data: null,
      };
    }
  }
};

export const postData = async <T>(
  endpoint: string,
  data: any,
): Promise<ApiResponse> => {
  try {
    if(axios.defaults.headers.common.Authorization){
      optionsAxios.headers.Authorization = axios.defaults.headers.common.Authorization
    }else{
      optionsAxios.headers.Authorization = '';
    }
    const apiService: AxiosInstance = axios.create(optionsAxios);
    const response: AxiosResponse<T> = await apiService.post(endpoint, data);
    return {
      status: true,
      code: response.status,
      message: 'Success',
      data: response.data,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        status: false,
        code: error.response ? error.response.status : 500,
        message: error.message,
        data: error.response ? error.response.data : null,
      };
    } else {
      return {
        status: false,
        code: 500,
        message: 'Unknown Error',
        data: null,
      };
    }
  }
};
