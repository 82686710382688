import React, {Fragment, useEffect, useState} from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    Legend,
    Bar, Rectangle, Cell
} from 'recharts';
import {motion} from 'framer-motion';
import {ApiStats} from "Api/ApiNazar";
import {Bounce, toast, ToastContainer} from "react-toastify";
import moment from "moment";
import 'moment/locale/fr';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import CardPrice from "../../Components/CardPrice";
import {Factory, Mouse, Storefront} from "@mui/icons-material";
import NumberAnimation from "../../Components/NumberAnimation";
import GlobalFunctions from "../../Scripts/GlobalFunctions";
import {styled} from "@mui/material/styles";

const getTotal = (data: any, key: string) => {
    return data.reduce((total: any, item: any) => total + parseFloat(item[key]), 0).toFixed(2);
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Edi = () => {
    const [totalOrder, setTotalOrder] = useState({total: 0, totalCom: 0, totalDrop: 0});
    const [topSaler, setTopSaler] = useState([]);
    const [caSaler, setCaSaler] = useState([]);
    const [totalSale, setTotalSale] = useState([]);
    const [totalClick, setTotalClick] = useState({
        nbClickDropOnDate: 0,
        nbClickEntrepotsOnDate: 0,
        nbClickTotalOnDate: 0,
        totalNbClickDrop: 0,
        totalNbClickEntrepots: 0,
        totalNbClickTotal: 0
    });
    const [totalClickListGamme, setTotalClickListGamme] = useState([]);
    const [totalClickListGammeDay, setTotalClickListGammeDay] = useState([]);
    let getTotalCaDrop = getTotal(totalSale, 'valueDrop');
    let getTotalCaCom = getTotal(totalSale, 'valueCom');


    const handleStats = async () => {
        try {
            const response = await ApiStats.fetchStatsEdi();
            console.log(response);
            if (response.status && response.data !== null) {
                if (response.data !== undefined && response.code === 200) {
                    moment.locale('fr');
                    setTotalOrder(response.data.totalOrder.length === 1 ? response.data.totalOrder[0] : []);
                    setTotalClick(response.data.totalClick.length === 1 ? response.data.totalClick[0] : []);
                    setTotalClickListGamme(response.data.totalClickListGamme.map((data: any) => {
                        return {
                            name: data.nomGamme,
                            valueDrop: parseInt(data.totalNbClickDrop),
                            valueCom: parseInt(data.totalNbClickEntrepots),
                        }
                    }));

                    setTotalClickListGammeDay(response.data.totalClickListGamme
                        .filter((data: any) => parseInt(data.nbClickTotalOnDate) > 0)
                        .map((data: any) => ({
                            name: data.nomGamme,
                            valueDrop: parseInt(data.nbClickDropOnDate),
                            valueCom: parseInt(data.nbClickEntrepotsOnDate),
                        }))
                    );

                    setCaSaler(response.data.topSaler.map((data: any) => {
                        return {
                            name: GlobalFunctions.convertToText(data.nom_societe),
                            value: data.total_ht,
                            color: GlobalFunctions.randomColor()
                        }
                    }));

                    setTopSaler(response.data.topSaler.map((data: any) => {
                        return {
                            name: GlobalFunctions.convertToText(data.nom_societe),
                            value: data.nb_commande,
                            color: GlobalFunctions.randomColor()
                        }
                    }));
                    setTotalSale(response.data.totalSale.map((data: any) => {
                        return {
                            name: moment(`${data.anneeCom}-${data.moisCom}`, "YYYY-MM").format("MMMM YYYY"),
                            valueDrop: data.nbProductDrop,
                            valueCom: data.nbProductCom,
                        }
                    }));

                } else {
                    toast.error('Une erreur inconnu est survenu.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                }
            } else if (response.data !== undefined && response.data.error !== undefined) {
                toast.error(`Erreur ${response.code} : ${response.data.error}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else {
                toast.error('Une erreur inconnu est survenu.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } catch (e) {
            toast.error(`Une erreur est survenu : ${e}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    useEffect(() => {
        handleStats();
    }, []);

    return (
        <Fragment>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.5}}
            >
                <ToastContainer/>
                <div style={{marginBottom: 30}}>
                    <Typography variant={'h1'} fontWeight={'800'} fontSize={26}>Statistique Edi</Typography>
                </div>

                <div>
                    <Typography variant={'h2'} fontWeight={'600'} color={'#696969'} fontSize={20}
                                marginBottom={2}>Total Click</Typography>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}>
                        <CardPrice
                            titleCard={`Total Click`}
                            iconCard={<Mouse/>}
                            valueCard={<NumberAnimation valueNumber={totalClick.totalNbClickTotal}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                        <CardPrice
                            titleCard={`Total Click dropshipping`}
                            iconCard={<Storefront/>}
                            valueCard={<NumberAnimation valueNumber={totalClick.totalNbClickDrop}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                        <CardPrice
                            titleCard={`Total Click entrepots`}
                            iconCard={<Factory/>}
                            valueCard={<NumberAnimation valueNumber={totalClick.totalNbClickEntrepots}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                    </div>
                    <div
                        style={{width: '100%', height: 300, padding: 20, backgroundColor: '#f1efef', borderRadius: 10}}>
                        <ResponsiveContainer>
                            <BarChart
                                key={Math.random()}
                                width={500}
                                height={300}
                                data={totalClickListGamme}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="valueCom" name={'Click Palette'} fill="#737F75"
                                     activeBar={<Rectangle fill="#737F75" stroke="#737F75"/>}/>
                                <Bar dataKey="valueDrop" name={'Click Drop'} fill="#DBBB92"
                                     activeBar={<Rectangle fill="#DBBB92" stroke="#DBBB92"/>}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div>
                    <Typography variant={'h2'} fontWeight={'600'} color={'#696969'} fontSize={20}
                                marginBottom={2}>Total click aujourd'hui</Typography>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}>
                        <CardPrice
                            titleCard={`Total Click aujourd'hui`}
                            iconCard={<Mouse/>}
                            valueCard={<NumberAnimation valueNumber={totalClick.nbClickTotalOnDate}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                        <CardPrice
                            titleCard={`Total Click dropshipping aujourd'hui`}
                            iconCard={<Storefront/>}
                            valueCard={<NumberAnimation valueNumber={totalClick.nbClickDropOnDate}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                        <CardPrice
                            titleCard={`Total Click entrepots aujourd'hui`}
                            iconCard={<Factory/>}
                            valueCard={<NumberAnimation valueNumber={totalClick.nbClickEntrepotsOnDate}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                    </div>
                    <div
                        style={{width: '100%', height: 300, padding: 20, backgroundColor: '#f1efef', borderRadius: 10}}>
                        <ResponsiveContainer>
                            <BarChart
                                key={Math.random()}
                                width={500}
                                height={300}
                                data={totalClickListGammeDay}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="valueCom" name={'Click Palette'} fill="#737F75"
                                     activeBar={<Rectangle fill="#737F75" stroke="#737F75"/>}/>
                                <Bar dataKey="valueDrop" name={'Click Drop'} fill="#DBBB92"
                                     activeBar={<Rectangle fill="#DBBB92" stroke="#DBBB92"/>}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div>
                    <Typography variant={'h2'} fontWeight={'600'} color={'#696969'} fontSize={20}
                                marginBottom={2}>Total Click</Typography>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}>
                        <CardPrice
                            titleCard={`Total`}
                            iconCard={<Mouse/>}
                            valueCard={<NumberAnimation valueNumber={totalOrder.total}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                        <CardPrice
                            titleCard={`Total dropshipping`}
                            iconCard={<Storefront/>}
                            valueCard={<NumberAnimation valueNumber={totalOrder.totalDrop}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                        <CardPrice
                            titleCard={`Total entrepots`}
                            iconCard={<Factory/>}
                            valueCard={<NumberAnimation valueNumber={totalOrder.totalCom}
                                                        isEuro={false}
                                                        style={{fontSize: 25, fontWeight: '600'}}/>}
                        />
                    </div>
                    <div
                        style={{width: '100%', height: 300, padding: 20, backgroundColor: '#f1efef', borderRadius: 10}}>
                        <ResponsiveContainer>
                            <BarChart
                                key={Math.random()}
                                width={500}
                                height={300}
                                data={topSaler}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend
                                    payload={
                                        topSaler.map(
                                            (item: any, index) => ({
                                                id: item.name,
                                                type: "square",
                                                value: `${item.name}`,
                                                color: item.color
                                            })
                                        )
                                    }
                                />
                                <Bar dataKey="value">
                                    {
                                        topSaler.map((entry: any, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} stroke={entry.color}
                                                  strokeWidth={index === 2 ? 4 : 1}/>
                                        ))
                                    }
                                </Bar>

                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div>
                    <Typography variant={'h2'} fontWeight={'600'} color={'#696969'} fontSize={20}
                                marginBottom={2}>Total Click</Typography>
                    <div
                        style={{width: '100%', height: 300, padding: 20, backgroundColor: '#f1efef', borderRadius: 10}}>
                        <ResponsiveContainer>
                            <BarChart
                                key={Math.random()}
                                width={500}
                                height={300}
                                data={caSaler}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend
                                    payload={
                                        caSaler.map(
                                            (item: any, index) => ({
                                                id: item.name,
                                                type: "square",
                                                value: `${item.name}`,
                                                color: item.color
                                            })
                                        )
                                    }
                                />
                                <Bar dataKey="value" stackId="1">
                                    {
                                        caSaler.map((entry: any, index) => (
                                            <Cell  key={`cell-${index}`} fill={entry.color} stroke={entry.color}
                                                  strokeWidth={index === 2 ? 4 : 1}/>
                                        ))
                                    }
                                </Bar>

                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div>
                    <Typography variant={'h2'} fontWeight={'600'} color={'#696969'} fontSize={20}
                                marginBottom={2}>Total CA</Typography>
                    <div
                        style={{width: '100%', height: 700, padding: 20, backgroundColor: '#f1efef', borderRadius: 10}}>
                        <ResponsiveContainer>
                            <AreaChart
                                data={totalSale}
                                margin={{
                                    top: 20, right: 20, bottom: 20, left: 20,
                                }}
                            >
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Legend/>
                                <Area dataKey="valueCom" stackId="1" stroke="#737F75" fill="#737F75"
                                      name={'CA Palette'}/>
                                <Area dataKey="valueDrop" stackId="2" stroke="#DBBB92" fill="#DBBB92"
                                      name={'CA Dropshipping'}/>
                                <Tooltip/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="Ca table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>CA HT DROP</StyledTableCell>
                                        <StyledTableCell>CA HT PALETTE</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {totalSale.map((row: any) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.valueDrop}</StyledTableCell>
                                            <StyledTableCell align="left">{row.valueCom}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    <StyledTableRow>
                                        <StyledTableCell>Total HT
                                            : {(parseFloat(getTotalCaDrop) + parseFloat(getTotalCaCom)).toFixed(2)}</StyledTableCell>
                                        <StyledTableCell>{getTotalCaDrop}</StyledTableCell>
                                        <StyledTableCell>{getTotalCaCom}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </motion.div>
        </Fragment>
    );
};

export default Edi;
