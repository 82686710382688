import React, {Fragment, useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {ApiStats} from "Api/ApiNazar";
import {Bounce, toast, ToastContainer} from "react-toastify";
import moment from "moment";
import 'moment/locale/fr';
import {Typography, Card, CardContent, Grid} from "@mui/material";
import NumberAnimation from "Components/NumberAnimation";
import CardPrice from "Components/CardPrice";

const Home = () => {
    const [statsYear, setStatsYear] = useState([]);
    const [statsOneYear, setStatsOneYear] = useState({totalHtTotal: 0, totalHtCommande: 0, totalHtCommandeMarketplace: 0});
    const [maxYAxisValue, setMaxYAxisValue] = useState(0);

    const handleStats = async () => {
        try {
            const response = await ApiStats.fetchStatsGlobal();
            console.log(response);
            if (response.status && response.data !== null) {
                if (response.data !== undefined && response.code === 200) {
                    moment.locale('fr');
                    setStatsOneYear(response.data.caOneYear[0]);
                    setStatsYear(response.data.caTotalDate.map((data: any) => {
                        const monthName = moment(`${data.annee}-${data.mois}`, "YYYY-MM").format("MMMM YYYY");
                        const formattedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
                        return {
                            name: formattedMonthName,
                            value: parseFloat(data.totalHtCommande).toFixed(2)
                        };
                    }));
                    const maxDataValue = Math.max(...response.data.caTotalDate.map((entry: any) => parseFloat(entry.totalHtCommande)));
                    const maxAxis = parseFloat((maxDataValue * 1.1).toFixed(2));
                    setMaxYAxisValue(maxAxis);
                } else {
                    toast.error('Une erreur inconnu est survenu.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                }
            } else {
                toast.error('Une erreur inconnu est survenu.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } catch (e) {
            toast.error(`Une erreur est survenu : ${e}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    useEffect(() => {
        handleStats();
    }, []);

    return (
        <Fragment>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.5}}
            >
                <ToastContainer/>


                {/* Ajout des graphiques HTML */}
                <div style={{marginTop: 30}}>
                    <Typography variant={'h2'} fontWeight={'600'} color={'#696969'} fontSize={20} marginBottom={2}>
                        Graphiques supplémentaires
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h3" fontWeight="600" fontSize={18} gutterBottom>
                                        CA de Juillet à Juin 2024
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" fontStyle="italic" gutterBottom>
                                        Chiffre d'affaires total pour la période de Juillet 2023 à Juin 2024.
                                    </Typography>
                                    <iframe title="CA de Juillet à Juin 2024" src="/GraphsHTML/ca_total.html" width="100%" height="100px" style={{border: 'none'}}></iframe>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h3" fontWeight="600" fontSize={18} gutterBottom>
                                        CA Marketplace de Juillet à Juin 2024
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" fontStyle="italic" gutterBottom>
                                        Chiffre d'affaires généré par les marketplaces pour la période de Juillet 2023 à Juin 2024.
                                    </Typography>
                                    <iframe title="CA Marketplace de Juillet à Juin 2024" src="/GraphsHTML/ca_total_marketplace.html" width="100%" height="100px" style={{border: 'none'}}></iframe>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h3" fontWeight="600" fontSize={18} gutterBottom>
                                        CA Commerciaux de Juillet à Juin 2024
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" fontStyle="italic" gutterBottom>
                                        Chiffre d'affaires généré par les commerciaux pour la période de Juillet 2023 à Juin 2024.
                                    </Typography>
                                    <iframe title="CA Commerciaux de Juillet à Juin 2024" src="/GraphsHTML/ca_total_commercial.html" width="100%" height="100px" style={{border: 'none'}}></iframe>
                                </CardContent>
                            </Card>
                        </Grid>
                        {[
                            {
                                title: 'Ventes par Mois',
                                src: '/GraphsHTML/revenue_per_month.html',
                                description: 'Ce graphique montre les ventes mensuelles, calculées à partir des commandes et des factures générées.'
                            },
                            {
                                title: 'Chiffre d\'Affaires Total par Mois',
                                src: '/GraphsHTML/ca_total_par_mois.html',
                                description: 'Ce graphique montre le chiffre d\'affaires total mensuel, calculé à partir des factures générées.'
                            },
                            {
                                title: 'Chiffre d\'Affaires Commerciaux',
                                src: '/GraphsHTML/commercial_revenue.html',
                                description: 'Ce graphique montre le chiffre d\'affaires généré par les commerciaux, basé sur les factures générées.'
                            },
                            {
                                title: 'Avoirs par Mois',
                                src: '/GraphsHTML/credit_notes_per_month.html',
                                description: 'Ce graphique montre les avoirs mensuels, calculés à partir des notes de crédit générées.'
                            },
                            {
                                title: 'Chiffre d\'Affaires Marketplace',
                                src: '/GraphsHTML/marketplace_revenue.html',
                                description: 'Ce graphique montre le chiffre d\'affaires généré par les marketplaces, basé sur les commandes passées.'
                            },
                            {
                                title: 'Chiffre d\'Affaires Net par Mois (Ventes - Avoirs)',
                                src: '/GraphsHTML/net_revenue_per_month.html',
                                description: 'Ce graphique montre le chiffre d\'affaires net mensuel, calculé en soustrayant les avoirs des ventes.'
                            },
                            {
                                title: 'Commandes par Distributeur',
                                src: '/GraphsHTML/orders_by_distributor.html',
                                description: 'Ce graphique montre le nombre total de commandes passées par chaque distributeur.'
                            },

                        ].map((graph, index) => (
                            <Grid item xs={12} key={index}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h3" fontWeight="600" fontSize={18} gutterBottom>
                                            {graph.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" fontStyle="italic" gutterBottom>
                                            {graph.description}
                                        </Typography>
                                        <iframe title={graph.title} src={graph.src} width="100%" height="500px" style={{border: 'none'}}></iframe>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </motion.div>
        </Fragment>
    );
};

export default Home;
