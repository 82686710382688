const GlobalFunctions = {
  addTenToHexColor(hexColor: string, numberAdd: number = 10) {
    let red: number = parseInt(hexColor.substring(1, 3), 16);
    let green: number = parseInt(hexColor.substring(3, 5), 16);
    let blue: number = parseInt(hexColor.substring(5, 7), 16);

    red = Math.max(0, Math.min(255, red - (100 - numberAdd)));
    green = Math.max(0, Math.min(255, green - (100 - numberAdd)));
    blue = Math.max(0, Math.min(255, blue - (100 - numberAdd)));

    const redHex: string = red.toString(16).padStart(2, '0');
    const greenHex: string = green.toString(16).padStart(2, '0');
    const blueHex: string = blue.toString(16).padStart(2, '0');
    return `#${redHex}${greenHex}${blueHex}`;
  },
  toEuro(number: number) {
    return number.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    });
  },
  randomColor ()  {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },
  convertToText(text:string) {
    let elem = document.createElement("textarea");
    elem.innerHTML = text;
    return elem.value;
  },

};

export default GlobalFunctions;
