import {RouterProvider, createBrowserRouter, Navigate} from "react-router-dom";
import { useAuth } from 'Provider/AuthProvider';
import { ProtectedRoute } from "./ProtectedRoute";
import Authentication from 'Pages/Authentication';
import Home from "Pages/Home";
import StatsProduits from "Pages/Stats/StatsProduits";
import Edi from "Pages/Stats/Edi";

const Routes = () => {
    const { logout, isConnected } = useAuth();

    const handleLogout = () => {
        logout();
        return  <Navigate to="/login" />;
    }

    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
            children: [
                {
                    path: "/",
                    element: <Home/>,
                },
                {
                    path: "/stats/product",
                    element: <StatsProduits/>,
                },
                {
                    path: "/stats/edi",
                    element: <Edi/>,
                },
                {
                    path: "/login",
                    element: <Navigate to="/" />,
                },
                {
                    path: "/logout",
                    element: handleLogout,
                },
            ],
        },
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
        {
            path: "/",
            element: <Navigate to="/login" />
        },
        {
            path: "/login",
            element: <Authentication />,
        },
    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...(!isConnected() ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;
