import AuthProvider from "./Provider/AuthProvider";
import Routes from "./Routes";
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <AuthProvider>
            <Routes />
        </AuthProvider>
    );
}

export default App;
