import React, {ReactNode} from 'react';
import {Typography} from "@mui/material";

interface CardPriceInterface {
    titleCard: string;
    iconCard: ReactNode;
    valueCard: ReactNode;
}
const CardPrice: React.FC<CardPriceInterface> = ({titleCard, iconCard, valueCard})=> {
    return (
        <div style={{padding: 5}}>
            <Typography variant={'h2'} fontWeight={'600'} color={'#696969'} fontSize={20}
                        marginBottom={2}>{titleCard}</Typography>
            <div>
                <div style={{
                    width: 250,
                    height: 150,
                    padding: 20,
                    backgroundColor: '#f1efef',
                    borderRadius: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{
                        backgroundColor: '#ffffff',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 5
                    }}>
                        {iconCard}
                    </div>
                    <div>
                        {valueCard}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPrice;
