const Constants ={
    logo: '/assets/Logo/nazarrugs.png',
    imgLogin: '/assets/Fond/img_block.webp',
    backgroundLogin: '/assets/Fond/bg_nazarrugs.jpg',
    nameApplication: 'Nazar rugs',
    color: {
        mainColor : '#F1EEE5'
    }
}

export default Constants;
