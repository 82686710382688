import { ApiResponse, postData} from '../ApiService';

const AUTH_API = '/user/AuthentificationWeb.php';

const ApiAuthentification = {
  fetchUser: (username: string, password: string): Promise<ApiResponse> => {
    return postData(AUTH_API, {username, password});
  },
};

export default ApiAuthentification;
