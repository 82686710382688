import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from '../Provider/AuthProvider';
import {Fragment, useEffect, useState} from "react";
import Navigation from "../Components/Navigations";
import CssBaseline from "@mui/material/CssBaseline";
import Box from '@mui/material/Box';
import Constants from "Scripts/Constants";
import {AnimatePresence} from "framer-motion";

export const ProtectedRoute = () => {
    const { token, user } = useAuth();
    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
        if (token && user === null) {
            return;
        }

        setAuthChecked(true);
    }, [token, user]);

    if (!authChecked) {
        return <div>Loading...</div>;
    }

    if (!token || !user) {
        return <Navigate to="/" />;
    }

    return (
        <Fragment>
                <Box sx={{ display: 'flex', zIndex: 10, backgroundColor: Constants.color.mainColor, height: '100vh'}}>
                    <CssBaseline />
                    <Navigation />
                    <Box component="main" sx={{ flexGrow: 1, p: 3, zIndex: 5, backgroundColor: 'white', borderRadius: '30px', overflow: 'hidden', margin: 1 }}>
                        <div style={{overflow: "auto", height: '100%'}}>
                            <AnimatePresence mode="wait">
                                <Outlet />
                            </AnimatePresence>
                        </div>
                    </Box>
                </Box>
        </Fragment>
    );
};
