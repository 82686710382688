import React, {Fragment} from 'react';
import { AppBar, Toolbar, Typography, Container, Button } from '@mui/material';
import {useAuth} from "Provider/AuthProvider";
import { motion, AnimatePresence } from 'framer-motion';
const StatsProduits = () => {
    const {  user, logout } = useAuth();
    return (
        <Fragment>
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
        >
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        My App Produits
                    </Typography>
                    <Button color="inherit" onClick={logout}>Logout</Button>
                </Toolbar>
            </AppBar>
            <Container>
                <Typography variant="h2" align="center" mt={5}>
                    Welcome to My App : {user?.firstName} {user?.lastName} !
                </Typography>
                <Typography variant="body1" align="center" mt={2}>
                    This is a simple homepage built with React and Material-UI.
                </Typography>
            </Container>
        </motion.div>
        </Fragment>
    );
};

export default StatsProduits;
